import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Details from "../components/Details.jsx"
import Compositions from '../components/Compositions.jsx';
import "../styles/FicheMatch.scss"
import subst from "../assets/sub.png"
import redcard from "../assets/redcard.png"


const FicheMatch = () => {
    const [match, setMatch] = useState(null);

    const [live, setLive] = useState(false)
    const [details, setDetails] = useState(true)
    const [compos, setCompos] = useState(false)
    const [selected, setSelected] = useState(true)
    const [selected2, setSelected2] = useState(false)
    const [selected3, setSelected3] = useState(false)

    const openDetails = () => {
        setDetails(true)
        setLive(false)
        setCompos(false)
        setSelected(true)
        setSelected2(false)
        setSelected3(false)

    }


    const openCompos = () => {
        setCompos(true)
        setLive(false)
        setDetails(false)
        setSelected(false)
        setSelected2(true)
        setSelected3(false)

    }

    const openLive = () => {
        setLive(true)
        setCompos(false)
        setDetails(false)
        setSelected(false)
        setSelected2(false)
        setSelected3(true)

    }


    const { id } = useParams()

    useEffect(() => {
        // Fetch data
        fetch(`https://v3.football.api-sports.io/fixtures?id=${id}`, {
            method: "GET",
            headers: {
                "x-rapidapi-key": "5ff22ea19db11151a018c36f7fd0213b",
                "x-rapidapi-host": "v3.football.api-sports.io",
            }
        })
            .then((response) => response.json())
            .then((result) => setMatch(result.response[0]))
            .catch((error) => {console.error(error)});
    }, []);

    console.log(match)


if (!match){
    return <div>loading...</div>
}

const round = match.league.round

const roundd = round.slice(round.length -2)
console.log(round)
console.log(roundd)


const date = new Date(match.fixture.date)

const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`
const formattedHour = `${date.getHours().toString().padStart(2, '0')}h${date.getMinutes().toString().padStart(2, '0')}`    
   

if (match.statistics.length <= 0 && match.lineups.length <=0){
    return <div className='bloc'>
    <section>
    <div className="ligue"><p>{match.league.name} - Journee {roundd}</p></div>
<div className="datelieu">
    <span>{formattedDate} - {formattedHour}</span>
    <span>{match.fixture.venue.name} , {match.fixture.venue.city}</span>
</div>
<div className="affiche">
    <div className='domicile'>
        <img src={match.teams.home.logo} alt={`logo ${match.teams.home.name}`} />
        <p>{match.teams.home.name}</p>
    </div>
    <div className='score'>
        <span>{match.goals.home}</span> - <span>{match.goals.away}</span>
    </div>
    <div className='exterieur'>
        <img src={match.teams.away.logo} alt={`logo ${match.teams.away.name}`} />
        <p>{match.teams.away.name}</p>
    </div>
</div>
</section>
</div>
}

if (match.statistics.length <= 0) {
    return <div className='bloc'>
    <section>
    <div className="ligue"><p>{match.league.name} - Journee {roundd}</p></div>
<div className="datelieu">
    <span>{formattedDate} - {formattedHour}</span>
    <span>{match.fixture.venue.name} , {match.fixture.venue.city}</span>
</div>
<div className="affiche">
    <div className='domicile'>
        <img src={match.teams.home.logo} alt={`logo ${match.teams.home.name}`} />
        <p>{match.teams.home.name}</p>
    </div>
    <div className='score'>
        <span>{match.goals.home}</span> - <span>{match.goals.away}</span>
    </div>
    <div className='exterieur'>
        <img src={match.teams.away.logo} alt={`logo ${match.teams.away.name}`} />
        <p>{match.teams.away.name}</p>
    </div>
</div>
</section>
</div>
}
/*
    if(match.statistics.length <= 0){
        return <div>
            <Compositions match={match} titulairesDom={match.lineups[0].startXI} titulairesExt={match.lineups[1].startXI} substituteDom={match.lineups[0].substitutes} substituteExt={match.lineups[1].substitutes} coachDom={match.lineups[0].coach.name} coachExt={match.lineups[1].coach.name} systemeDom={match.lineups[0].formation} systemeExt={match.lineups[1].formation} compoDom={match.lineups[0]} compoExt={match.lineups[1]}/>
    
        </div>
    }*/
    
    

const buteurs = match.events.filter((element) =>
    element.type === "Goal"
)

const buteurHome = buteurs.filter (function (buteur){
  return  buteur.team.name === match.teams.home.name
})

const buteurExt = buteurs.filter(function(buteur){
    return buteur.team.name === match.teams.away.name
})

//PARTIE DETAILS

const stats = match.statistics.filter((element)=>
element.statistics
)

const statss = stats.map((element)=>
element.statistics )

const poss = statss.map((element) => 
element.filter((element)=> element.type === "Ball Possession"))

const xg = statss.map((element) => 
    element.filter((element)=> element.type === "expected_goals"))

const tirs = statss.map((element) => 
    element.filter((element)=> element.type === "Total Shots"))

const tirsCadres = statss.map((element) => 
    element.filter((element)=> element.type === "Shots on Goal"))

const jaune = statss.map((element) => 
    element.filter((element)=> element.type === "Yellow Cards"))

const rouge = statss.map((element) => 
    element.filter((element)=> element.type === "Red Cards"))

const passes = statss.map((element) => 
    element.filter((element)=> element.type === "Total passes"))

const passesReussies = statss.map((element) => 
    element.filter((element)=> element.type === "Passes accurate"))

const accuracy = statss.map((element) => 
    element.filter((element)=> element.type === "Passes %"))

// PARTIE COMPOS

const compoDom = match.lineups[0]
const compoExt = match.lineups[1]

const coachDom = compoDom.coach.name
const coachExt = compoExt.coach.name

const systemeDom = compoDom.formation
const systemeExt = compoExt.formation


const tituDom = match.players[0].players.slice(0, 11)
const tituExt = match.players[1].players.slice(0, 11)

const substituteDom = match.players[0].players.slice(11, match.players[0].players.length)
const substituteExt = match.players[1].players.slice(11, match.players[1].players.length)

const remplacement = match.events.filter((element)=>
element.detail.indexOf( "Substitution"))

console.log(remplacement)

console.log(substituteDom)





console.log(tituDom)





    return (
        <div className='bloc'>
            <section>
            <div className="ligue"><p>{match.league.name} - Journee {roundd}</p></div>
        <div className="datelieu">
            <span>{formattedDate} - {formattedHour}</span>
            <span>{match.fixture.venue.name} , {match.fixture.venue.city}</span>
        </div>
        <div className="affiche">
            <div className='domicile'>
                <img src={match.teams.home.logo} alt={`logo ${match.teams.home.name}`} />
                <p>{match.teams.home.name}</p>
            </div>
            <div className='score'>
                <span>{match.goals.home}</span> - <span>{match.goals.away}</span>
            </div>
            <div className='exterieur'>
                <img src={match.teams.away.logo} alt={`logo ${match.teams.away.name}`} />
                <p>{match.teams.away.name}</p>
            </div>
        </div>
        
        <div className="buts">
            <div className="equipeDomicile">{buteurHome.map((element)=> <li key={`buteur: ${element.player.name}`}><i class="fa-regular fa-futbol"></i> {element.player.name}, {element.time.elapsed}' {element.time.extra ? ` + ${element.time.extra}` : null} {element.detail === "Own Goal" ?<em> (csc) </em> : null} {element.detail === "Penalty" ? <em>(pen)</em> : null}</li>)}</div>
            <div className="equipeExt">{buteurExt.map((element)=> <li key={`buteurExt: ${element.player.name}`}><i class="fa-regular fa-futbol"></i> {element.player.name}, {element.time.elapsed}' {element.time.extra ? ` + ${element.time.extra}` : null} {element.detail === "Own Goal" ? <em>(csc)</em> : null} {element.detail === "Penalty" ? <em>(pen)</em> : null}</li>)}</div>
        </div>
        {match.players.length === 0 ? "" :
        <div className='blocInfos'>
        <nav className="fiche__selecteur">
                <ul>
                    {selected ? <li className="details selected">Details</li>: <li onClick={openDetails} className="details">Details</li> }
                    {selected2 ? <li className="compos selected">Compos</li> : <li onClick={openCompos} className="compos">Compos</li>}
                    {selected3 ? <li className="lives selected">Live</li> : <li onClick={openLive} className="lives">Live</li>}

                </ul>
                </nav>
                { details ?
<Details match={match} possession={poss} expectedGoals={xg} tirs={tirs} tirsCadres={tirsCadres} jaune={jaune} rouge={rouge} passes={passes} passesReussies={passesReussies} accuracy={accuracy} />
:
compos ?
<Compositions match={match} titulairesDom={tituDom} titulairesExt={tituExt} substituteDom={substituteDom} substituteExt={substituteExt} coachDom={coachDom} coachExt={coachExt} systemeDom={systemeDom} systemeExt={systemeExt} compoDom={compoDom} compoExt={compoExt}/> :
live ?
<section className="evenements">
<h3>Match en Live</h3>
<article className="events">
{match.events.map((element) => (
element.team.name === match.teams.home.name ? (
<div className="domicile" key={element.id}>
<span>
{element.detail === "Yellow Card" ? (
<>
<p>{element.player.name}</p>
<img width="28" height="28" src="https://img.icons8.com/color/48/soccer-yellow-card.png" alt="soccer-yellow-card" />
</>
) : element.detail === "Red Card" ? (
<>
<p>{element.player.name}</p>
<img width="28" height="28" src={redcard} alt="foul"/>
</>
) : element.type === "Goal" ? (
<>
<div className="goal">
<p className="buteur"> {element.player.name} ⚽ {element.detail === "Penalty" ? "(Pen.)" : ""}</p>
{element.assist.name != null ? <p className="passeDec"> (Passe D: {element.assist.name})</p> : ""}
</div>
</>
) : element.type === "subst" ? (
<>
<div className="changement">
<p>{element.player.name}</p>
<img src={subst} className="subst" alt="changement"/>
<p>{element.assist.name}</p>
</div>
</>
) : element.detail === "Goal Disallowed - offside" ? (
<>
<p>But refusé (Hors-Jeu)</p>
<img width="28" height="28" alt="var" src="https://img.icons8.com/external-kosonicon-outline-kosonicon/64/external-var-replay-soccer-and-football-match-kosonicon-outline-kosonicon.png" />

</>
) :
element.detail === "Goal cancelled" ? (
<>
<p>But refusé"</p>
<img width="28" height="28" alt="var" src="https://img.icons8.com/external-kosonicon-outline-kosonicon/64/external-var-replay-soccer-and-football-match-kosonicon-outline-kosonicon.png" />
</>
): element.detail === "Penalty confirmed" ? (
<>
<p>Penalty confirmé</p>
<img width="28" height="28" alt="var" src="https://img.icons8.com/external-kosonicon-outline-kosonicon/64/external-var-replay-soccer-and-football-match-kosonicon-outline-kosonicon.png" />

</>
) : element.detail}
</span>
<em>{element.time.elapsed}'</em>
</div>
) : (
<div className="exterieur" key={element.id}>
<em>{element.time.elapsed}'</em>
<span>
{element.detail === "Yellow Card" ? (
<>
<img width="28" height="28" src="https://img.icons8.com/color/48/soccer-yellow-card.png" alt="soccer-yellow-card" />
<p>{element.player.name}</p>
</>
) : element.detail === "Red Card" ? (
<>
<img width="28" height="28" src={redcard} alt="redcard"/>
<p>{element.player.name}</p>
</>
) :
element.type === "Goal" ? (
<>
<div className="goal">
<p className="buteur">⚽ {element.player.name} {element.detail === "Penalty" ? "(Pen.)" : ""}</p>
{element.assist.name != null ? <p className="passeDec"> (Passe D: {element.assist.name})</p> : ""}
</div>
</>
) :
element.type === "subst" ? 
<>
<div className="changement">
<p>{element.player.name}</p>
<img src={subst} className="subst" alt='changement' />
<p>{element.assist.name}</p>
</div>
</> : element.detail === "Goal Disallowed - offside" ? (
<>
<img width="28" height="28" alt="var" src="https://img.icons8.com/external-kosonicon-outline-kosonicon/64/external-var-replay-soccer-and-football-match-kosonicon-outline-kosonicon.png" />
<p>But refusé (Hors-Jeu)</p> 
</>) :
element.detail === "Goal cancelled" ? (
<>
<img width="28" height="28" alt="var" src="https://img.icons8.com/external-kosonicon-outline-kosonicon/64/external-var-replay-soccer-and-football-match-kosonicon-outline-kosonicon.png" />
<p>But refusé</p></>
): element.detail === "Penalty confirmed" ? (
<>
<img width="28" height="28" alt="var" src="https://img.icons8.com/external-kosonicon-outline-kosonicon/64/external-var-replay-soccer-and-football-match-kosonicon-outline-kosonicon.png" />
<p>Penalty confirmé</p>
</>) : (element.detail
)}
</span>
</div>
)
))}    </article>
</section>:
""

           } {/* other rendering logic */}
       </div> }
            </section>
        
        </div>
    );

};

export default FicheMatch;