import { Link } from "react-router-dom"
import "../styles/Apropos.scss"

function Apropos() {

    return (
        <div className="blocApropos">
        <article className="apropos">
            <h3>Bienvenue sur 11/10 !</h3>
            <p>Vous pourrez retrouver ici toutes les infos concernant le ballon rond; Les resultats des matchs de la veille? Le classement de votre équipe favorite? Les statistiques des joueurs ou simplement la date du prochain choc? Toutes ces infos sont disponibles sur 11/10.</p>
            <p>11/10 est une WebApplication, qui au contraire d'un site web classique, necessite tres peu de temps de chargement lors des changements de page, vous offrant une navigation fluide et agreable.</p>
            <p>L'application est nouvelle et encore en construction, n'hesitez donc pas à venir nous rendre visite regulierement pour voir les dernieres mises a jour. Vous pouvez aussi nous faire part de vos suggestions afin d'ameliorer 11/10 via le <Link to="/Contact">formulaire de contact</Link> ; Positive ou negative, toute critique est bonne a prendre! </p>
            <p>Bonne visite à Tous!</p>
        </article>
        </div>
    )

}

export default Apropos