import TableauEurope from "../components/TableauEurope";
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import "../styles/FicheSelections.scss"
import "../styles/FicheChampionnat.scss"
import TableauSelections from "../components/TableauSelections";

function FicheSelections({ setFilter }) {

    const { id } = useParams();
    //const league = leagues.find((league) => league.id === id)

    const [classement, setClassement] = useState()

    useEffect(() => {
        // Fetch data
        fetch(`https://v3.football.api-sports.io/standings?league=${id}&season=2024`, {
            method: "GET",
            headers: {
                "x-rapidapi-key": "5ff22ea19db11151a018c36f7fd0213b",
                "x-rapidapi-host": "v3.football.api-sports.io",
            }
        })
            .then((response) => response.json())
            .then((result) => setClassement(result.response[0].league.standings))
            .catch((error) => {console.error(error)});
    }, []);

    if(!classement){
        return <div>loading</div>
    }
console.log(classement)
const classementPlat = classement.flatMap(arr => arr);

return (
    <div className="blocFicheSelections">
    <TableauSelections id={id} />

    <div className="tableaux">
        {classement.map((subArray, index) => (
            <div key={"group" + index} className="groupe">
                <h3>{subArray[0].group}</h3> {/* Optionnel : titre pour chaque groupe */}
                <div className="barre">
                    <div>Equipe</div>
                    <div>J</div>
                    <div>V</div>
                    <div>N</div>
                    <div>D</div>
                    <div>Pts</div>
                </div>
                <ul className="liste">
                
                    {subArray.map((element) => (
                        <li className="equipe" key={"champ" + element.team.id}>
                            <div>{element.rank}</div>
                            <img className="flags" src={element.team.logo} alt="logo equipe" />
                            <p>{element.team.name}</p>
                            <div>{element.all.played}</div>
                            <div>{element.all.win}</div>
                            <div>{element.all.draw}</div>
                            <div>{element.all.lose}</div>
                            <span>{element.points}</span>
                        </li>
                    ))}
                </ul>
            </div>
        ))}
    </div>
    </div>
);
}

export default FicheSelections