import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
//import App from './App';
import Header from './components/Header'
import Footer from './components/Footer';
import Avenir from './components/Avenir';
import Contact from './Pages/Contact';
import Apropos from './Pages/Apropos';
import Home from './Pages/Home';
import LivePage from './Pages/LivePage';
import Clubs from './Pages/Clubs';
import Selections from './Pages/Selections';
import FicheChampionnat from './Pages/FicheChampionnat';
import FicheEurope from './Pages/FicheEurope';
import FicheSelections from './Pages/FicheSelections';
import FicheMatch from './Pages/FicheMatch';
import FicheLive from './Pages/FicheLive';
import FicheJoueur from './Pages/FicheJoueur';
import Confidentialite from './Pages/Confidentialité';


ReactDOM.render(
  <React.StrictMode>
      <Router>
        <Header />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/FicheChampionnat/:id' element={<FicheChampionnat />} />
            <Route path='/FicheEurope/:id' element={<FicheEurope />} />
            <Route path='/FicheMatch/:id' element={<FicheMatch />} />
            <Route path='/FicheSelections/:id' element={<FicheSelections />} />
            <Route path='/FicheJoueur/:id' element={<FicheJoueur />} />
            <Route path='/FicheLive/:id' element={<FicheLive />} />
            <Route path='/Contact' element={<Contact />} />
            <Route path='/Apropos' element={<Apropos />} />
            <Route path='/Clubs' element={<Clubs />} />
            <Route path='/Selections' element={<Selections />} />
            <Route path='/Live' element={<LivePage />} />
            <Route path="/*" element={<Home />} />
            <Route path='/Confidentialite' element={<Confidentialite />} />
          </Routes>
          <Avenir />
          <Footer />
      </Router>
  </React.StrictMode>,
document.getElementById('root')
)



