import React, { useEffect, useState } from "react";
import banner2 from "../assets/banner3.webp";
import banner3 from "../assets/banner4.jpg";
import banner4 from "../assets/banner5.jpg";
import banner5 from "../assets/banner6.jpg";
import banner6 from "../assets/banner7.jpg";
import banner7 from "../assets/banner8.webp";
import banner8 from "../assets/banner9.webp";
import banner9 from "../assets/banner10.webp";
import banner10 from "../assets/banner11.jpg";
import banner11 from "../assets/banner12.webp";
import banner12 from "../assets/banner13.webp";
import banner13 from "../assets/banner14.jpg";
import banner14 from "../assets/banner15.webp";
import banner15 from "../assets/banner16.jpg";
import banner16 from "../assets/banner17.webp";
import banner17 from "../assets/banner18.jpg";
import banner18 from "../assets/banner19.webp";
import banner19 from "../assets/banner20.webp"
import banner20 from "../assets/banner21.webp"
import banner21 from "../assets/banner22.webp"
import banner22 from "../assets/banner23.webp"




import Filtres from "../components/Filtres";
import "../styles/Home.scss";
import Live from "../components/Live";
import Aujourdhui from "../components/Aujourdhui";

function Home() {

    const photosBan = [banner2, banner3, banner4, banner5, banner6, banner7, banner8, banner9, banner10, banner11, banner12, banner13, banner14, banner15, banner16, banner17, banner18, banner19, banner20, banner21, banner22];

    const [randomPhoto, setRandomPhoto] = useState(photosBan[Math.floor(Math.random() * photosBan.length)]);
    const [isActive, setIsActive] = useState(true); // Pour gérer l'activation de l'image


    function aleatoire(max) {
        return Math.floor(Math.random() * max);
    }

    const change = () => {
        setIsActive(false); // Désactive l'image actuelle
        setTimeout(() => {
            let random = aleatoire(photosBan.length);
            setRandomPhoto(photosBan[random]);
            setIsActive(true); // Réactive l'image après changement
        }, 300); // Correspond à la durée de l'effet de fondu
    };

    useEffect(() => {
        const interval = setInterval(change, 6000); // Changer toutes les 6 secondes
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="blocpage">
            <div className="banner">
                <img
                    src={randomPhoto}
                    alt="banniere du site avec des images de foot"
                    className={isActive ? "active" : ""}
                />
                <div className="banner__text">
                    <h1>100% FOOT</h1>
                    <h2>Toutes vos infos Football en un clic</h2>
                </div>
            </div>
            <Aujourdhui />
            <Live />
            <Filtres />
        </div>
    );
}

export default Home;