import "../styles/Compositions.scss"
import flecheVerte from "../assets/flecheverte.png"
import flecheRouge from "../assets/flecherouge.png"
import redcard from "../assets/redcard.png"
import { Link } from "react-router-dom"

function Compositions({match, titulairesDom, titulairesExt, coachDom, coachExt, systemeDom, systemeExt, substituteDom, substituteExt }) {
console.log(titulairesDom)

    const range = [1, 2, 3, 4, 5]

    console.log(titulairesDom.map((element) => element.statistics[0].goals.total))

    return (
        <article>

            <h3 className="compoTitle">Compositions d'équipe</h3>
            <div className="joueurs">
                <ul className="joueurs__domicile">
                    <div className="headerCompo">
                <img src={match.teams.home.logo} alt={` logo ${match.teams.home.name}`} className="logoCompo" />
                <div>
                    <h4>{systemeDom}</h4>
                    <h5>Titulaires</h5>
                    </div>
                    </div>
                    <div className="titulaires">
                    {titulairesDom.map((element) =>
                       <Link to={`/FicheJoueur/${element.player.id}`}><li key={"joueur " + element.player.name}>
                           {element.statistics[0].games.position === "G" ? <img width="28" height="28" className="maillot" src="https://img.icons8.com/dotty/80/hockey-glove.png" alt="hockey-glove"/>  : <img width="28" height="28" className="maillot" src="https://img.icons8.com/external-bartama-outline-64-bartama-graphic/64/external-Jersey-sport-outline-bartama-outline-64-bartama-graphic.png" alt="jersey" />}
                          <div className="joueur">
                            <p>{element.player.name}</p>
                            <div className="joueur__infos">
                            <strong>{element.statistics[0].games.number}</strong>
                            {range.map((x) => element.statistics[0].goals.total >= x ? <span className="goal">⚽</span> : null)} {element.statistics[0].cards.yellow >= 1 ? <img width="48" height="48" src="https://img.icons8.com/color/48/soccer-yellow-card.png" alt="soccer-yellow-card"/> : ""} {element.statistics[0].cards.red >= 1 ? <img width="48" height="48" src={redcard} alt="foul"/> : ""}{element.statistics[0].games.minutes < 90 && match.fixture.status.long === "Match Finished" ? <div className="changement"><em>{element.statistics[0].games.minutes}'</em> <img src={flecheRouge} className="fleche" alt="sortie" /></div> : ""}
                            </div>
                            </div>
                           </li>
                           </Link>
                    )}
                    </div>
                    <h5>Remplaçants</h5>
                    <div className="remplacants">
                    {substituteDom.map((element) =>
                       <Link to={`/FicheJoueur/${element.player.id}`}><li key={"joueur" + element.player.name}>
                           {element.statistics[0].games.position === "G" ? <img width="28" height="28" className="maillot" src="https://img.icons8.com/dotty/80/hockey-glove.png" alt="hockey-glove"/>  : <img width="28" height="28" className="maillot" src="https://img.icons8.com/external-bartama-outline-64-bartama-graphic/64/external-Jersey-sport-outline-bartama-outline-64-bartama-graphic.png" alt="jersey" />}
                           <div className="joueur">
                        <p>{element.player.name}</p>
                        <div className="joueur__infos">
                        <strong>{element.statistics[0].games.number}</strong>
                         {range.map((x) => element.statistics[0].goals.total >= x ? <span className="goal">⚽</span> : null)} {element.statistics[0].cards.yellow >= 1 ? <img width="48" height="48" src="https://img.icons8.com/color/48/soccer-yellow-card.png" alt="soccer-yellow-card"/> : ""} {element.statistics[0].cards.red >= 1 ? <img width="48" height="48" src={redcard} alt="foul"/> : ""}{element.statistics[0].games.minutes != null ? <div className="changement"><em>{90 - element.statistics[0].games.minutes}'</em><img src={flecheVerte} className="fleche" alt="entree" /> </div>: ""}
                         </div>
                         </div>
                         </li>
                         </Link>
                    
                    )}
                    </div>
                    <p>Coach: {coachDom}</p>

                </ul>
                <ul className="joueurs__ext">
                    <div className="headerCompo">
                    <div>
                    <h4>{systemeExt}</h4>
                    <h5>Titulaires</h5>
                    </div>
                    <img src={match.teams.away.logo} alt={` logo  ${match.teams.away.name}`} className="logoCompo" />
                    </div>
                    <div className="titulaires">
                    {titulairesExt.map((element) =>
                       <Link to={`/FicheJoueur/${element.player.id}`}> <li key={"joueur " + element.player.name}>
                        {element.statistics[0].games.position === "G" ? <img width="28" height="28" className="maillot" src="https://img.icons8.com/dotty/80/hockey-glove.png" alt="hockey-glove"/>  : <img width="28" height="28" className="maillot" src="https://img.icons8.com/external-bartama-outline-64-bartama-graphic/64/external-Jersey-sport-outline-bartama-outline-64-bartama-graphic.png" alt="jersey" />}
                       <div className="joueur">
                         <p>{element.player.name}</p>
                         <div className="joueur__infos">
                         {range.map((x) => element.statistics[0].goals.total >= x ? <span className="goal">⚽</span> : null)} {element.statistics[0].cards.yellow >= 1 ? <img width="48" height="48" src="https://img.icons8.com/color/48/soccer-yellow-card.png" alt="soccer-yellow-card"/> : ""} {element.statistics[0].cards.red >= 1 ? <img width="48" height="48" src={redcard} alt="foul"/> : ""}{element.statistics[0].games.minutes < 90 && match.fixture.status.long === "Match Finished" ? <div className="changement"><em>{element.statistics[0].games.minutes}'</em> <img src={flecheRouge} className="fleche" alt="sortie" /></div> : ""}
                         <strong>{element.statistics[0].games.number}</strong>
                         </div>
                         </div>
                        </li>
                        </Link>)}
                    </div>

                    <h5>Remplaçants</h5>
                    <div className="remplacants">
                    {substituteExt.map((element) =>
                       <Link to={`/FicheJoueur/${element.player.id}`}> <li key={"joueur" + element.player.name}>
                        {element.statistics[0].games.position === "G" ? <img width="28" height="28" className="maillot" src="https://img.icons8.com/dotty/80/hockey-glove.png" alt="hockey-glove"/>  : <img width="28" height="28" className="maillot" src="https://img.icons8.com/external-bartama-outline-64-bartama-graphic/64/external-Jersey-sport-outline-bartama-outline-64-bartama-graphic.png" alt="jersey" />}
                        <div className="joueur">
                     <p>{element.player.name}</p>
                     <div className="joueur__infos">
                      {range.map((x) => element.statistics[0].goals.total >= x ? <span className="goal">⚽</span> : null)} {element.statistics[0].cards.yellow >= 1 ? <img width="48" height="48" src="https://img.icons8.com/color/48/soccer-yellow-card.png" alt="soccer-yellow-card"/> : ""} {element.statistics[0].cards.red >= 1 ? <img width="48" height="48" src={redcard} alt="foul"/> : ""}{element.statistics[0].games.minutes != null ? <div className="changement"><em>{90 - element.statistics[0].games.minutes}'</em><img src={flecheVerte} className="fleche" alt="entree" /> </div>: ""}
                      <strong>{element.statistics[0].games.number}</strong>
                      </div>
                      </div>
                      </li>
                      </Link>
                    )}
                </div>
                <p>Coach: {coachExt} </p>

                </ul>

            </div>
        </article>
    )
}

export default Compositions