import "../styles/Filtres.scss"
import { Link } from "react-router-dom"
import { selections } from "../Datas/Leagues"

function Selections() {


    return (
        <div className="blocSelections">
            <section className="competitions">
                <div className="conteneur">
                    <div className="title">
                        <h3>Competitions</h3>
                    </div>
                    <div className="filtres">
                        {selections.map(({ name, id, logo,}) =>
                            <Link className="lien" to={`/FicheSelections/${id}`} key={"lien" + id}>                               
                                <h4>{name}</h4>
                                <article><img src={logo} alt="Logo du Championnat" /></article>
                            </Link>
                        )}

                       
                    </div>
                </div>
             
            </section>
        </div>
    )
}

export default Selections