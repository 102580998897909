import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ligue1 from "../assets/logoligue1.webp"

function Aujourdhui(){

    const [matchsEngland, setMatchsEngland] = useState([]);
const [matchsSpain, setMatchsSpain] = useState([]);
const [matchsFrance, setMatchsFrance] = useState([]);
const [matchsUcl, setMatchsUcl] = useState([]);
const [matchsGer, setMatchsGer] = useState([]);
const [matchsItaly, setMatchsItaly] = useState([]);




    useEffect(()=> {
        const fetchUcl= ()=>{
            try {
                 fetch("https://v3.football.api-sports.io/fixtures?league=2&season=2024", {
            method: "GET",
             headers: {
                "x-rapidapi-key": "5ff22ea19db11151a018c36f7fd0213b",
                "x-rapidapi-host": "v3.football.api-sports.io",
            }
        })
.then((response)=> response.json()) 
.then((json)=>{

    setMatchsUcl(json.response)

})      
        
       }
       catch (error){
        console.error("error:", error)
       }};
       fetchUcl();}, []

       )

    useEffect(()=> {
        const fetchFrance = ()=>{
            try {
                 fetch("https://v3.football.api-sports.io/fixtures?league=61&season=2024", {
            method: "GET",
             headers: {
                "x-rapidapi-key": "5ff22ea19db11151a018c36f7fd0213b",
                "x-rapidapi-host": "v3.football.api-sports.io",
            }
        })
.then((response)=> response.json()) 
.then((json)=>{

    setMatchsFrance(json.response)

})      
        
       }
       catch (error){
        console.error("error:", error)
       }};
       fetchFrance();}, []

       )


    useEffect(()=> {
        const fetchEngland = ()=>{
            try {
                 fetch("https://v3.football.api-sports.io/fixtures?league=39&season=2024", {
            method: "GET",
             headers: {
                "x-rapidapi-key": "5ff22ea19db11151a018c36f7fd0213b",
                "x-rapidapi-host": "v3.football.api-sports.io",
            }
        })
.then((response)=> response.json()) 
.then((json)=>{

    setMatchsEngland(json.response)

})      
        
       }
       catch (error){
        console.error("error:", error)
       }};
       fetchEngland();}, []

       )

       useEffect(()=> {
        const fetchSpain = ()=>{
            try {
                 fetch("https://v3.football.api-sports.io/fixtures?league=140&season=2024", {
            method: "GET",
             headers: {
                "x-rapidapi-key": "5ff22ea19db11151a018c36f7fd0213b",
                "x-rapidapi-host": "v3.football.api-sports.io",
            }
        })
.then((response)=> response.json()) 
.then((json)=>{

    setMatchsSpain(json.response)

})      
        
       }
       catch (error){
        console.error("error:", error)
       }};
       fetchSpain();}, []

       )

       useEffect(()=> {
        const fetchGer= ()=>{
            try {
                 fetch("https://v3.football.api-sports.io/fixtures?league=78&season=2024", {
            method: "GET",
             headers: {
                "x-rapidapi-key": "5ff22ea19db11151a018c36f7fd0213b",
                "x-rapidapi-host": "v3.football.api-sports.io",
            }
        })
.then((response)=> response.json()) 
.then((json)=>{

    setMatchsGer(json.response)

})      
        
       }
       catch (error){
        console.error("error:", error)
       }};
       fetchGer();}, []

       )

       useEffect(()=> {
        const fetchItaly= ()=>{
            try {
                 fetch("https://v3.football.api-sports.io/fixtures?league=135&season=2024", {
            method: "GET",
             headers: {
                "x-rapidapi-key": "5ff22ea19db11151a018c36f7fd0213b",
                "x-rapidapi-host": "v3.football.api-sports.io",
            }
        })
.then((response)=> response.json()) 
.then((json)=>{

    setMatchsItaly(json.response)

})      
        
       }
       catch (error){
        console.error("error:", error)
       }};
       fetchItaly();}, []

       )

       if (matchsEngland.length === 0 || matchsSpain.length === 0 || matchsFrance.length === 0 || matchsUcl.length === 0 || matchsGer.length === 0 || matchsItaly.length === 0) {
        return <div>Loading...</div>
    }
       const matchs = [...matchsUcl, ...matchsFrance, ...matchsEngland, ...matchsSpain, ...matchsGer, ...matchsItaly]

       console.log(matchs)

       const matchDate = matchs.map((element)=> element.fixture.date)
       const matchday = matchDate.map((element)=> element.slice(0,10))

       const today = new Date();

// Récupérer l'année, le mois et le jour
const year = today.getFullYear();
const month = String(today.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0, donc +1
const day = String(today.getDate()).padStart(2, '0'); // Ajouter un 0 devant les jours < 10

// Formater la date dans le format souhaité : YYYY-MM-DD
const dateDuJour = `${year}-${month}-${day}`;

console.log(dateDuJour); // Exemple : 2024-08-1

const todayMatch = matchs.filter(function(match){
    return match.fixture.date.slice(0,10) === dateDuJour
})


const formatDateAndTime = (dateString) => {
    const matchDate = new Date(dateString);

    const formattedDate = `${matchDate.getDate().toString().padStart(2, "0")}/${(
      matchDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}`;
    const formattedHour = `${matchDate
      .getHours()
      .toString()
      .padStart(2, "0")}h${matchDate.getMinutes().toString().padStart(2, "0")}`;

    return { formattedDate, formattedHour };
  };

       console.log(matchday)
       console.log(todayMatch)
       


       return(
        <div className="live">
            <h3 className="titreToday">AUJOURDHUI</h3>
            { todayMatch.length === 0 ? <p className="nomatch"> Pas de match aujourdhui</p> :
            <article className="live__tableau">
            {todayMatch.map((element)=> element.fixture.status.long === "Not Started" ?
            <Link className="lienMatch" to={`/FicheMatch/${element.fixture.id}`} >
            <li className="ligne__match">
                <img src={element.league.logo === "https://media.api-sports.io/football/leagues/61.png" ? ligue1 : element.league.logo} className="match__competition" alt="logo competition" />
            <p className="match__equipeDom">{element.teams.home.name}</p>
            <img className="match__logoDom" src={element.teams.home.logo} alt="logo domicile" />
            -
            <img className="match__logoExt" src={element.teams.away.logo} alt="logo exterieur" />
            <p className="match__equipeExt">{element.teams.away.name}</p>
            <div className="match__rdv">
              <div className="rdv">
                <em>{formatDateAndTime(element.fixture.date).formattedDate}{" "}</em>
             <em> {formatDateAndTime(element.fixture.date).formattedHour}</em>
             </div>
            </div>
      
          </li>
          </Link>
          :
          ""
        
       )
}
</article>
}
</div>
       )

}

export default Aujourdhui