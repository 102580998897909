import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../styles/FicheJoueur.scss"
import redcard from "../assets/redcard.png"
import ligue1 from "../assets/logoligue1.webp"

function FicheJoueur() {


    const [joueur, setJoueur] = useState();
    const [palmares, setPalmares] = useState()

    const [openPalmares, setOpenPalmares] = useState(false)
    const [rotateP, setRotationP] = useState(true)

    const collapsePalmares = () => {
        setOpenPalmares(!openPalmares)
        setRotationP(!rotateP)
    }


    const { id } = useParams()



    useEffect(() => {
        fetch(`https://v3.football.api-sports.io/trophies?player=${id}`, {
            method: "GET",
            headers: {
                "x-rapidapi-key": "5ff22ea19db11151a018c36f7fd0213b",
                "x-rapidapi-host": "v3.football.api-sports.io",
            }
        })
            .then((response) => response.json())
            .then((result) => setPalmares(result.response))
            .catch((error) => { console.error(error) });
    }, []);

    useEffect(() => {
        fetch(`https://v3.football.api-sports.io/players?id=${id}&season=2024`, {
            method: "GET",
            headers: {
                "x-rapidapi-key": "5ff22ea19db11151a018c36f7fd0213b",
                "x-rapidapi-host": "v3.football.api-sports.io",
            }
        })
            .then((response) => response.json())
            .then((result) => setJoueur(result.response[0]))
            .catch((error) => { console.error(error) });
    }, []);

    if (!joueur) {
        return (
            <div>loading</div>
        )
    }

    if (!palmares) {
        return (
            <div>loading...</div>
        )
    }

    console.log(joueur)

    const teamNames = joueur.statistics.map((element) => element.team.name);
    const teamss = joueur.statistics.map((element) => element)

    const uniqueTeamNames = joueur.statistics.reduce((acc, element) => {
        if (!acc.includes(element.team.logo) && element.games.minutes > 0) {
            acc.push(element.team.logo);
        }
        return acc;
    }, []);

    console.log(uniqueTeamNames);
    const dateStr = "1992-06-15"; // La date au format ISO

    // Convertir la chaîne en objet Date
    const date = new Date(joueur.player.birth.date);

    // Formater la date au format français (jj/mm/aaaa)
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('fr-FR', options);

    console.log(formattedDate);

    const trophees = palmares.filter((element) => element.place === "Winner")
    console.log(trophees)

    const trophies = trophees.reduce((acc, trophy) => {
        if (!acc[trophy.league]) {
            acc[trophy.league] = [];
        }
        acc[trophy.league].push(trophy);
        return acc;
    }, {});

    // Convertir l'objet regroupé en un tableau
    const trophiesArray = Object.entries(trophies).map(([league, trophies]) => ({
        league,
        trophies
    }));

    console.log(trophiesArray);

    console.log(trophies);

    return (
        <div className="blocJoueur">
            <article>
                <section className="infosJoueur">
                    <img src={joueur.player.photo} alt={`joueur: ${joueur.player.name}`} className="infosJoueur__photo" />
                    <aside className="infosJoueur__bio">
                        <div>
                            <h3>{joueur.player.name}</h3>
                            <p>Né le {formattedDate} à {joueur.player.birth.place}, {joueur.player.birth.country}</p>
                            <p>Taille : {joueur.player.height}, Poids : {joueur.player.weight}</p>
                            <p>Poste: {joueur.statistics[0].games.position === "Attacker" ? "Attaquant" : joueur.statistics[0].games.position === "Midfielder" ? "Milieu" : joueur.statistics[0].games.position === "Defender" ? "Defenseur" : joueur.statistics[0].games.position === "Goalkeeper" ? "Gardien" : ""}</p>
                        </div>
                        <div className="logos" >{uniqueTeamNames.map((element, index) => <img src={element} alt={"logo equipe"} key={`logo-${index}`} className="logo" />)}</div>
                    </aside>
                </section>
                <section className="palmares">
                    <div className="palmares__titre" onClick={collapsePalmares}> <h4>Palmares</h4> {rotateP ? <i class="fa-solid fa-chevron-down" ></i> : <i class="fa-solid fa-chevron-down actif" ></i>}</div>
                    {openPalmares ?
                        <ul className="palmares__infos ouvert">
                            {trophiesArray.map((element) =>
                                <li>{element.trophies.length}x {element.league}</li>)}
                        </ul>
                        :
                        <ul className="palmares__infos ferme">
                            {trophiesArray.map((element) =>
                                <li>{element.trophies.length}x {element.league}</li>)}
                        </ul>
                    }
                </section>
                <strong>2024/2025</strong>
                <section className="statsJoueur">
                    {joueur.statistics.map((element) =>
                        <div key={"fiche " + joueur.player.name + " " + element.league.name}> {element.games.minutes <= 0 || element.games.minutes === null ? "" :
                            <div>
                                <h4>{element.league.name === "Friendlies" ? "Amicaux" : element.league.name}</h4>
                                <div className="liste">
                                    <ul>
                                        <li><img width="20" height="20" src="https://img.icons8.com/quill/100/football.png" alt="football" /> Matchs joués: {element.games.appearences}</li>
                                        <li><img width="20" height="20" src="https://img.icons8.com/pastel-glyph/64/football-goal.png" alt="football-goal" /> Buts: {element.goals.total}</li>
                                        <li><img width="20" height="20" src="https://img.icons8.com/wired/64/define-location.png" alt="define-location" />Passes Dec: {element.goals.assists}</li>
                                        <li><img width="20" height="20" src="https://img.icons8.com/cotton/64/football-kick.png" alt="football-kick" /> Tirs (cadrés): {element.shots.total} {element.shots.on === null ? "" : `(${element.shots.on})`}</li>
                                        <li><img width="20" height="20" src="https://img.icons8.com/ios-glyphs/30/cleats.png" alt="cleats" />Passes: {element.passes.total}</li>
                                        <li><img width="20" height="20" src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/64/external-rating-user-flatart-icons-outline-flatarticons.png" alt="rating" />Note moyenne: {element.games.rating === null ? "" : element.games.rating.slice(0, 4)}</li>
                                        <li><img width="20" height="20" src="https://img.icons8.com/color/48/soccer-yellow-card.png" alt={"carton jaune " + element.league.name } className="jaune" />Cartons jaune: {element.cards.yellow}</li>
                                        <li><img width="20" height="20" src={redcard} alt="foul" className="rouge" />Cartons Rouge: {element.cards.red}</li>

                                    </ul>
                                    <img src={element.league.logo === "https://media.api-sports.io/football/leagues/61.png" ? ligue1 : element.league.logo} alt={"logo" + element.league.name} className="logoCompet" />
                                </div>
                            </div>}
                        </div>)}

                </section>
            </article>
        </div>
    )
}


export default FicheJoueur