import "../styles/Avenir.scss"
import appli from "../assets/appli.png"
import appli2 from "../assets/appli2.png"

function Avenir(){

    return (
        <section className="avenir">
            <div>
                <img src={appli} alt="appli phone"/>
                <p>Application Mobile A Venir...</p>
            </div>
            <img  className="phone" src={appli2} alt="phone"/>
        </section>
    )
}

export default Avenir