import "../styles/Header.scss"
import logo from "../assets/logoblanc.png"
import { Link } from 'react-router-dom'
import Menu from "./Menu.jsx"


function Header() {
    return (
    <header>
        <Link to="/Home"><img src={logo} alt="logo du site"/></Link>
        <Menu />
    </header>
    )
}

export default Header